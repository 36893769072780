import CartInterface, { CartItemInterface } from '../../interfaces/CartInterface'
import ChannelInterface from '../../interfaces/ChannelInterface'
import ProductInterface from '../../interfaces/ProductInterface'

export type SignInParams = {
  pathname: string,
  method: string,
}

export type AdjustCartParams = {
  adjQty: number,
  currency: string,
  item?: CartItemInterface,
  product?: ProductInterface,
  variantId?: number,
}

export type ViewItemParams = {
  product: ProductInterface,
  currency: string,
}

export type CartPaymentParams = {
  cart: CartInterface,
  method?: string,
}

// NOTE: use AppEvents values as keys
export interface SubscriberInterface {
  init?: () => void
  deinit?: () => void
  switchChannel?: (data?: ChannelInterface) => void
  showChannel?: (data?: ChannelInterface) => void
  signIn?: (data: SignInParams) => void
  signUp?: (data: SignInParams) => void
  clickCheckout?: (data: CartInterface) => void
  viewCart?: (data: CartInterface) => void
  viewCartAuth?: (data: CartInterface) => void
  viewCartShipping?: (data: CartInterface) => void
  viewCartPayment?: (data: CartInterface) => void
  purchase?: (data: CartInterface) => void
  adjustCart?: (data: AdjustCartParams) => void
  viewItem?: (data: ViewItemParams) => void
  addShippingInfo?: (data: CartInterface) => void
  addPaymentInfo?: (data: CartPaymentParams) => void
  clickPayment?: (data: CartInterface) => void
}

export enum AppEventsEnum {
  INIT = 'init',
  DEINIT = 'deinit',
  SWITCH_CHANNEL = 'switchChannel',
  SHOW_CHANNEL = 'showChannel',
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  CLICK_CHECKOUT = 'clickCheckout',
  VIEW_CART = 'viewCart',
  VIEW_CART_AUTH = 'viewCartAuth',
  VIEW_CART_SHIPPING = 'viewCartShipping',
  VIEW_CART_PAYMENT = 'viewCartPayment',
  PURCHASE = 'purchase',
  ADJUST_CART = 'adjustCart',
  VIEW_ITEM = 'viewItem',
  ADD_SHIPPING_INFO = 'addShippingInfo',
  ADD_PAYMENT_INFO = 'addPaymentInfo',
  CLICK_PAYMENT = 'clickPayment',
}

export interface TriggerEventInterface {
  (name: AppEventsEnum.INIT): void
  (name: AppEventsEnum.DEINIT): void
  (name: AppEventsEnum.SWITCH_CHANNEL, data?: ChannelInterface | null): void
  (name: AppEventsEnum.SHOW_CHANNEL, data?: ChannelInterface | null): void
  (name: AppEventsEnum.SIGN_UP, data: SignInParams): void
  (name: AppEventsEnum.SIGN_IN, data: SignInParams): void
  (name: AppEventsEnum.CLICK_CHECKOUT, data: CartInterface): void
  (name: AppEventsEnum.VIEW_CART, data: CartInterface): void
  (name: AppEventsEnum.VIEW_CART_AUTH, data: CartInterface): void
  (name: AppEventsEnum.VIEW_CART_SHIPPING, data: CartInterface): void
  (name: AppEventsEnum.VIEW_CART_PAYMENT, data: CartInterface): void
  (name: AppEventsEnum.PURCHASE, data: CartInterface): void
  (name: AppEventsEnum.ADJUST_CART, data: AdjustCartParams): void
  (name: AppEventsEnum.VIEW_ITEM, data: ViewItemParams): void
  (name: AppEventsEnum.ADD_SHIPPING_INFO, data: CartInterface): void
  (name: AppEventsEnum.ADD_PAYMENT_INFO, data: CartPaymentParams): void
  (name: AppEventsEnum.CLICK_PAYMENT, data: CartInterface): void
}
