import { Action, Location } from 'history'
import useIsMounted from 'ismounted'
import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Router, Switch } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import ChannelContext from '../../context/ChannelContext'
import { ChannelInterface, Type } from '../../interfaces/ChannelInterface'
import HistoryInterface from '../../interfaces/HistoryInterface'
import * as globalActions from '../../services/global/global.actions'
import { State } from '../../services/reducers'
import { fixPath } from '../../utils/path'
import ChannelResolver from '../ChannelProvider/utils/channelResolver'
import useAppEvents from '../../hooks/events/events'
import { AppEventsEnum } from '../../hooks/events/events.types'

const isJsxElement = (element: JSX.Element | false): element is JSX.Element => {
  return React.isValidElement(element)
}

const locationsAreEqual = (a?: Location, b?: Location): boolean => {
  return a?.pathname === b?.pathname && a?.search === b?.search && a?.key === b?.key
}

const getLocationPath = (history: HistoryInterface, location: Location): string => {
  const channelPathIndex = window.location.pathname.search(history.getChannelPath())
  return fixPath((channelPathIndex === -1 ? '' : history.getChannelPath()) + '/' + location.pathname)
}

type Props = {
  children: Array<JSX.Element | false>
  history: HistoryInterface
  channels: ChannelInterface[]
}

const ChannelSwitcher: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const isMounted = useIsMounted()

  const history: HistoryInterface = props.history
  const [location, setLocation] = React.useState(history.location)
  const children: Array<JSX.Element> = props.children.filter(isJsxElement).sort((a, b) => {
    const aWeight: number = a.props.type === Type.Main ? 1 : 0
    const bWeight: number = b.props.type === Type.Main ? 1 : 0
    return aWeight - bWeight
  })
  const channelResolver = new ChannelResolver(props.channels)

  const currentChannel = channelResolver.getCurrent()
  const alternateChannel = channelResolver.getAlternate()
  const mainChannel = channelResolver.getMain()
  const oppositeChannel = channelResolver.getOpposite()
  const locations = useSelector((state: State) => state.global.locations[currentChannel?.id!])
  const lastLocation = locations?.last
  const { triggerEvent } = useAppEvents()

  React.useEffect(() => {
    dispatch(
      globalActions.updateChannelSuccess({
        current: currentChannel!,
        alternate: alternateChannel!,
      }),
    )
    history.setChannelPath(currentChannel != null ? _.trim(currentChannel.path) : '/')
    triggerEvent(AppEventsEnum.SHOW_CHANNEL, currentChannel)
  }, [currentChannel?.id])

  React.useEffect(() => {
    const unlisten = history.listen((newLocation: Location, action: Action) => {
      if (!locationsAreEqual(location, newLocation)) {
        const newLocationCopy: Location = _.cloneDeep(newLocation)
        setLocation(newLocationCopy)
      }
    })

    return function cleanup() {
      if (unlisten && isMounted) {
        unlisten()
      }
    }
  }, [])

  React.useEffect(() => {
    if (!locationsAreEqual(lastLocation, location) && isMounted) {
      dispatch(
        globalActions.updateLocations({
          channelId: currentChannel?.id!,
          lastLocation: location,
          prevLocation: lastLocation,
        }),
      )
    }
  }, [lastLocation?.key, location.key])
  const fixedLocation: Location = _.cloneDeep(location)
  fixedLocation.pathname = fixPath(fixedLocation.pathname.replace(currentChannel?.path!, ''))
  return (
    <Router history={history}>
      <TransitionGroup component={null}>
        <CSSTransition key={currentChannel?.code} timeout={650} classNames={`slide-${currentChannel?.type}`} mountOnEnter unmountOnExit>
          <Switch location={fixedLocation}>
            {children.map((item: React.ReactElement<ChannelInterface>) => (
              <Route
                key={item.props.code}
                // channel={item.props}
                location={fixedLocation}
                path={fixedLocation.pathname}
                render={() => {
                  return (
                    <ChannelContext.Provider
                      value={{
                        alternate: alternateChannel!,
                        current: currentChannel!,
                        main: mainChannel!,
                        opposite: oppositeChannel!,
                      }}
                    >
                      <>{React.createElement(item.type, item.props)}</>
                    </ChannelContext.Provider>
                  )
                }}
              />
            ))}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Router>
  )
}

export default ChannelSwitcher
