import Skeleton from '@mui/material/Skeleton'
import _ from 'lodash'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { PhotoInterface, PhotoSetInterface, PhotosInterface } from '../../interfaces/PhotoInterface'
import LoadingSpinner from '../LoadingSpinner'

type PhotoSetType = 'product' | 'catalog' | 'thumb' | 'custom' | 'landing'
type PhotoTypeType = 'product' | 'catalog'

const defaultProps = {
  set: 'product',
  type: 'product',
} as {
  set: PhotoSetType
  type: PhotoTypeType
}

type Props = typeof defaultProps & {
  alt?: string
  classes?: string
  src: string | PhotosInterface | PhotoSetInterface
  secondSrc?: string | PhotosInterface | PhotoSetInterface
  width?: number
  height?: number
  noEmpty?: true
  minHeight?: number
}

export const IMAGE_NOT_FOUND = '/img/not_found.jpg'

function sortPosition(imageA: PhotoInterface, imageB: PhotoInterface) {
  return imageA.position - imageB.position
}

const getSourcePath = (
  src: string | PhotosInterface | PhotoSetInterface,
  second: boolean,
  type: PhotoTypeType,
  set: PhotoSetType,
  query?: string,
) => {
  let path = IMAGE_NOT_FOUND
  if (typeof src === 'string') {
    path = `${src}${query}`
  } else if (set && _.has(src, set) && _.isArray(src[set])) {
    src = src as PhotosInterface
    const photosTyped = src[set].filter(item => item[type])

    let foundPhotoIdx = photosTyped.sort(sortPosition).findIndex(item => {
      return item[type] && item[type] === true
    })

    if (second) {
      foundPhotoIdx = photosTyped[foundPhotoIdx + 1] ? foundPhotoIdx + 1 : foundPhotoIdx
    }
    path = `${src[set][foundPhotoIdx]?.url}${query}` || IMAGE_NOT_FOUND
  } else if (set && _.has(src, set) && _.isObject(src[set])) {
    path = `${(src as PhotoSetInterface)[set]?.url}${query}` || IMAGE_NOT_FOUND
  }

  return path
}

const Photo = (props: Props) => {
  const { classes, alt, width, height, src, noEmpty, type, secondSrc, set, minHeight } = props
  const [loaded, setLoaded] = React.useState<boolean>(false)

  //a bit guessing ;)
  const fixedSet = type === 'catalog' ? 'catalog' : set

  const query = width! > 0 || height! > 0 ? '?' + (width! > 0 ? `w=${width}` : '') + (height! > 0 ? `w=${height}` : '') : ''
  const srcPath = getSourcePath(src, false, type, fixedSet, query)
  const srcSecondPath = secondSrc ? getSourcePath(secondSrc, true, type, fixedSet, query) : false
  const [activeImage, setActiveImage] = useState<string>(srcPath)

  const onMouseOver = () => {
    if (typeof srcSecondPath === 'string') {
      setActiveImage(srcSecondPath)
    }
  }

  const onMouseOut = () => {
    if (typeof srcSecondPath === 'string') {
      setActiveImage(srcPath)
    }
  }

  const onImageLoaded = () => {
    setLoaded(true)
  }

  return (
    <>
      {!loaded ? <Skeleton classes={{ root: classes }} variant="rectangular" animation="pulse" /> : null}
      {!src && noEmpty ? null : (
        <img
          width="100%"
          height="100%"
          className={classes}
          style={{ minHeight }}
          src={activeImage}
          alt={alt}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onLoad={onImageLoaded}
        />
      )}
    </>
  )
}

Photo.defaultProps = defaultProps

export default Photo
